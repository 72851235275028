import React from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, IconButton, Drawer, Toolbar, Box, AppBar } from '@mui/material';
import { useTheme } from '@mui/material';
import './index.css';
import {useBreakpoints} from '../../../utils/breakpoints';

function Index() {
  const theme = useTheme();
  const { isMdUp } = useBreakpoints();
  const [open, setOpen] = React.useState(false);

  const iconButtonStyle = {backgroundColor: theme.palette.primary.contrastText, color: 'white', marginRight: theme.spacing(1)};

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{backgroundColor: theme.palette.primary.light, height: '100%', padding: theme.spacing(5), display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <img src={`${process.env.PUBLIC_URL}/logo.png`} className="App-logo" alt="logo" height={theme.spacing(5.5)} style={{marginRight: theme.spacing(.5)}}/>
            <img src={`${process.env.PUBLIC_URL}/logo_text.png`} className="App-logo" alt="logo" height={theme.spacing(4)} width={theme.spacing(15)}/>
          </div>
          <a target={'_blank'} href={'https://t.me/stratogenchat'} rel="noreferrer" >
            <IconButton size='small' style={{...iconButtonStyle, padding: theme.spacing(.8), width: theme.spacing(4.5), marginTop: theme.spacing(2), marginRight: 0, backgroundColor: theme.palette.secondary.main}}>
              <TelegramIcon/>
            </IconButton>
          </a>
          <a target={'_blank'} href={'https://twitter.com/StratogenTech'} rel="noreferrer" >
            <IconButton size='small' style={{...iconButtonStyle, padding: theme.spacing(.8), width: theme.spacing(4.5), marginTop: theme.spacing(1), marginRight: 0, backgroundColor: theme.palette.secondary.main}}>
              <XIcon/>
            </IconButton>
          </a>
        </Box>
      </Drawer>
      <AppBar position="fixed" style={{backgroundColor: 'transparent', boxShadow: 'unset'}}>
        <Toolbar>
          <Container maxWidth={'xl'} style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <img src={`${process.env.PUBLIC_URL}/logo.png`} className="App-logo" alt="logo" height={theme.spacing(5.5)} style={{marginRight: theme.spacing(.5)}}/>
              <img src={`${process.env.PUBLIC_URL}/logo_text.png`} className="App-logo" alt="logo" height={theme.spacing(4)} width={theme.spacing(15)}/>
            </div>
            <div style={{display: isMdUp ? 'block' : 'none'}}>
              <a target={'_blank'} href={'https://t.me/stratogenchat'} rel="noreferrer" >
                <IconButton size='small' style={{...iconButtonStyle, padding: theme.spacing(.8), backgroundColor: theme.palette.secondary.main}}>
                  <TelegramIcon/>
                </IconButton>
              </a>
              <a target={'_blank'} href={'https://twitter.com/StratogenTech'} rel="noreferrer" >
                <IconButton size='small' className='svg_icons' style={{...iconButtonStyle, padding: theme.spacing(1.35), backgroundColor: theme.palette.secondary.main}}>
                  <XIcon/>
                </IconButton>
              </a>
            </div>
            <div style={{display: isMdUp ? 'none' : 'block'}}>
              <IconButton
                size="large"
                edge="start"
                color="info"
                aria-label="menu"
                sx={{marginRight: theme.spacing(-2)}}
                onClick={() => setOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Index;
