import React from 'react';
import { useTheme } from '@mui/material';
import TitleSection from './Sections/TitleSection';
import Games from './Sections/Games';
import Partners from './Sections/Partners';

function Index() {
  const theme = useTheme();
  const background = {background: theme.palette.primary.main};

  return (
    <div>
        <TitleSection/>
        <div style={background}>
          <Partners/>
          <Games/>
        </div>
    </div>
  );
}

export default Index;
