import React from 'react';
import { Container, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material';
import {useBreakpoints} from '../../../utils/breakpoints';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';

function Games() {
  const theme = useTheme();
  const { isMdUp } = useBreakpoints();

  const iconButtonStyle = {backgroundColor: theme.palette.primary.contrastText, color: 'white', marginRight: theme.spacing(1)};

  return (
      <Container maxWidth={'lg'}>
        <div style={{paddingBottom: theme.spacing(14), overflow: 'hidden'}}>
          <div style={{display: 'flex', flexDirection: isMdUp ? 'row' : 'column', alignItems: 'center', justifyContent: 'center'}}>
            <img
              src={`${process.env.PUBLIC_URL}/terranora.png`}
              alt="terranora war"
              style={{
                height: isMdUp ? theme.spacing(50) : 'auto',
                width: isMdUp ? theme.spacing(55) : '100%',
                maxWidth: theme.spacing(55),
                borderTopLeftRadius: theme.spacing(1),
                borderBottomLeftRadius: theme.spacing(isMdUp ? 1 : 0),
                borderTopRightRadius: theme.spacing(isMdUp ? 0 : 1),
              }}
            />
            <div style={{width: isMdUp ? '40%' : 'auto', display: 'flex', flexDirection: 'column', padding: theme.spacing(3), justifyContent: 'center', alignItems: isMdUp ? 'start' : 'center'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: isMdUp ? 'start' : 'center'}}>
                  <Typography variant='h3' style={{color: 'white', fontSize: theme.spacing(4), fontWeight: '500', marginRight: theme.spacing(2)}}>Coming Soon!</Typography>
                </div>
                <Typography align={isMdUp ? 'left' : 'center'} style={{color: 'white', fontSize: theme.spacing(2), marginTop: theme.spacing(3)}}>
                  Join us on the brink of creation with TerraNora, a world where your strategic prowess shapes empires.
                  Our adventure is still being forged, promising a realm of strategy, alliances, and conquest.
                  Stay tuned for the unveiling – your destiny in TerraNora is being crafted now!
                </Typography>
              <div style={{display: 'flex', marginTop: theme.spacing(3), alignItems: 'center'}}>
                <a target={'_blank'} href={'https://t.me/stratogenchat'} rel="noreferrer" >
                  <IconButton size='small' style={{...iconButtonStyle, padding: theme.spacing(.8), backgroundColor: theme.palette.secondary.main, height: theme.spacing(4.8), width: theme.spacing(4.8)}}>
                    <TelegramIcon/>
                  </IconButton>
                </a>
                <a target={'_blank'} href={'https://twitter.com/StratogenTech'} rel="noreferrer" >
                  <IconButton size='small' className='svg_icons' style={{...iconButtonStyle, padding: theme.spacing(1.35), backgroundColor: theme.palette.secondary.main, height: theme.spacing(6), width: theme.spacing(6)}}>
                    <XIcon/>
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
  );
};

export default Games;
