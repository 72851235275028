import React from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import { Container, IconButton, Box, Divider, Typography } from '@mui/material';
import {useTheme} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './index.css';
import {useBreakpoints} from '../../../utils/breakpoints';

function Index() {
  const theme = useTheme();
  const { isMdUp } = useBreakpoints();

  const iconButtonStyle = {backgroundColor: theme.palette.primary.contrastText, color: 'white', marginRight: theme.spacing(1)};

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // for smooth scrolling
    });
  };

  return (
    <Box>
      <Divider/>
      <div style={{backgroundColor: '#191919', padding: theme.spacing(5, 0, isMdUp ? 5 : 2, 0)}}>
          <Container maxWidth={'xl'} style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: isMdUp ? 'row' : 'column', width: '100%'}}>
              <div style={{display: 'flex', flexDirection: 'column', maxWidth: theme.spacing(55)}}>
                <img src={`${process.env.PUBLIC_URL}/logo_text.png`} alt="logo" height={theme.spacing(4)} width={theme.spacing(16)} style={{marginBottom: theme.spacing(2)}}/>
              </div>
              <IconButton
                size='small'
                style={{...iconButtonStyle, padding: theme.spacing(.8), backgroundColor: 'transparent', width: theme.spacing(5), height: theme.spacing(5), display: isMdUp ? 'block' : 'none'}}
                sx={{
                  border: '1px solid white',
                  '&:hover': {
                    backgroundColor: 'white'
                  }
                }}
                onClick={scrollToTop}
              >
                <KeyboardArrowUpIcon/>
              </IconButton>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: theme.spacing(isMdUp ? 4 : 1), flexDirection: isMdUp ? 'row' : 'column', alignItems: isMdUp ? 'center' : 'start'}}>
              <Typography color={theme.palette.info.main} style={{fontWeight: '500', marginTop: isMdUp ? 0 : theme.spacing(0)}}>© 2024 TerraNora. All Rights Reserved.</Typography>
              <div style={{marginTop: isMdUp ? 0 : theme.spacing(2)}}>
                <a target={'_blank'} href={'https://t.me/stratogenchat'} rel="noreferrer" >
                  <IconButton size='small' style={{...iconButtonStyle, padding: theme.spacing(.8)}}>
                    <TelegramIcon/>
                  </IconButton>
                </a>
                <a target={'_blank'} href={'https://twitter.com/StratogenTech'} rel="noreferrer" >
                  <IconButton size='small' className='svg_icons' style={{...iconButtonStyle, padding: theme.spacing(1.35), marginRight: 0}}>
                    <XIcon/>
                  </IconButton>
                </a>
              </div>
            </div>
          </Container>
      </div>
    </Box>
  );
}

export default Index;
