import React from 'react';
import { Container, Button, Typography, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, IconButton } from '@mui/material';
import { useTheme } from '@mui/material';
import {useBreakpoints} from '../../../utils/breakpoints';
import CloseIcon from '@mui/icons-material/Close';

function TitleSection() {
  const theme = useTheme();
  const { isMdUp } = useBreakpoints();

  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [registrationComplete, setRegistrationComplete] = React.useState(false);

  const emailRef = React.useRef();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const dialogStyle = {
    backgroundColor: '#191919',
    border: '2px #d8c3a2 solid',
    borderRadius: theme.spacing(2),
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
  };

  const handleRegister = () => {
    if (!email) {
      // @ts-ignore
      emailRef?.current && emailRef.current.focus();
      return;
    }
    if (isEmailValid) {
      setRegistrationComplete(true);
    }
  };

  return (
    <>
      <Dialog maxWidth={'md'} open={open} onClose={handleClose} PaperProps={{ style: dialogStyle }}>
        <DialogContent sx={{display: 'flex', flexDirection: isMdUp ? 'row' : 'column', justifyContent: 'space-between', alignItems: 'center', padding: 0, width: '100%'}}>
          <img
            src={`${process.env.PUBLIC_URL}/war.png`}
            alt="terranora war"
            style={{
              height: isMdUp ? theme.spacing(45) : 'auto',
              width: isMdUp ? theme.spacing(45) : '100%',
              maxHeight: isMdUp ? 'unset' : theme.spacing(25),
              objectFit: 'cover', // Add this to maintain aspect ratio
              objectPosition: 'center', // Add this to center the image
              borderTopLeftRadius: theme.spacing(.5),
              borderBottomLeftRadius: theme.spacing(isMdUp ? .5 : 0),
              borderTopRightRadius: theme.spacing(isMdUp ? 0 : .5),
            }}
          />
          <div style={{padding: theme.spacing(3)}}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 0}}>
              <Typography variant='h1' align='center' sx={{fontSize: theme.spacing(3), color: 'white', fontWeight: 500, marginBottom: theme.spacing(2)}}>
                Register to closed beta!
              </Typography>
              {  isMdUp &&
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: theme.spacing(.5),
                    top: theme.spacing(.5),
                    color: theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
            </DialogTitle>
            {registrationComplete ? (
              <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                <Typography align='center' color='white' maxWidth={theme.spacing(25)}>
                  Congratulations! You've successfully registered for our closed beta!
                </Typography>
                <img
                  src={`${process.env.PUBLIC_URL}/logo.png`}
                  alt="terranora logo"
                  style={{
                    height: theme.spacing(10),
                    width: theme.spacing(10),
                    marginTop: theme.spacing(2)
                  }}
                />
              </div>
            ) : (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <DialogContentText>
                  <Typography sx={{fontSize: theme.spacing(2), color: 'white', maxWidth: theme.spacing(40), marginBottom: theme.spacing(3)}} align='center'>
                    Register to closed beta to receive the invitation to the game at the end of this year.
                  </Typography>
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  fullWidth
                  margin="dense"
                  id="email"
                  name="email"
                  label="Enter your Email"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={handleEmailChange}
                  inputRef={emailRef}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d8c3a2',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d8c3a2',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#d8c3a2',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#d8c3a2',
                      '&.Mui-focused': {
                        color: '#d8c3a2', // Label color on focus
                      },
                    },
                    '& .Mui-focused .MuiInputLabel-root': {
                      color: '#d8c3a2',
                    },
                  }}
                />
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'end', width: '100%'}}>
                  { !isMdUp &&
                    <Button onClick={handleClose} sx={{ color: 'white', fontWeight: 500, marginRight: theme.spacing(1) }}>Cancel</Button>
                  }
                  <Button
                    variant='contained'
                    sx={{
                      color: 'white',
                      fontWeight: 600,
                      height: theme.spacing(5),
                      marginTop: theme.spacing(1),
                      backgroundColor: '#d8c3a2', // Use backgroundColor instead of background
                      '&:hover': {
                        backgroundColor: '#bbaa91' // Optional: Change the color on hover
                      }
                    }}
                    onClick={handleRegister}
                    fullWidth={isMdUp}
                    disabled={!isEmailValid && !registrationComplete}
                  >
                    <Typography color='white'>
                      Register
                    </Typography>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <div style={{
        backgroundImage: `linear-gradient(to top, ${theme.palette.primary.main}, rgba(0, 0, 0, 0.1)), url(${process.env.PUBLIC_URL}/background.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top, top, center',
        backgroundSize: 'cover',
      }}>
        <Container maxWidth={'lg'}>
          <div style={{display: 'flex', flexDirection: isMdUp ? 'row' : 'column-reverse', alignItems: isMdUp ? 'start' : 'center', justifyContent: 'center', padding: theme.spacing(isMdUp ? 35 : 20, 0,  isMdUp ? 35 : 20, 0)}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: isMdUp ? theme.spacing(2, 0, 0, 0) : theme.spacing(0, 0, 5 ,0), width: isMdUp ? '60%' : '100%'}}>
              <Typography variant='h1' align='center' style={{color: 'white', fontSize: theme.spacing(isMdUp ? 6 : 5), fontWeight: '500'}}>TerraNora Is Waiting For You!</Typography>
              <Typography align='center' style={{color: 'white', fontSize: theme.spacing(2.2), marginTop: theme.spacing(3), maxWidth: theme.spacing(65)}}>
                Enter TerraNora, where strategy defines your legacy.
                Build, battle, and rule in a dynamic world blending magic and might.
                Forge alliances, command armies, and shape the destiny of your empire. Join the adventure where every choice is a step towards victory.
              </Typography>
              <div style={{display: 'flex', flexDirection: 'row', marginTop: theme.spacing(4), flexWrap: 'wrap'}}>
                <Button onClick={() => handleOpen()} color={'secondary'} variant="contained" sx={{textTransform: 'none', borderRadius: theme.spacing(.5), fontWeight: '500', width: theme.spacing(12)}}>
                  <Typography align='center' style={{color: 'white', fontSize: theme.spacing(1.7), lineHeight: 'unset'}}>
                    Register
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      </>
  );
}

export default TitleSection;
