'use client';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'caudex',
    ].join(','),
    h1: {
      fontFamily: 'caudex',
    },
    body1: {
      fontFamily: 'arvo',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#1e1e1f',
      dark: '#18181b',
      light: '#2b2b2e',
      contrastText: '#404041'
    },
    secondary: {
      main: '#716f94'
    },
    info: {
      main: '#959595',
      light: '#e9e9e9'
    }
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === 'info' && {
            backgroundColor: '#60a5fa',
          }),
        }),
      },
    },
  },
});

export default theme;